import React, { useState } from 'react';

import { JoinSteps, MembershipTypes, RLPDonorOptions } from './types';
import { Header1, Header2 } from '../headers';
import { numberWithCommas } from '../../utils';
import RadioButton from '../forms/radioButton';
import MembershipToggle from './membershipToggle';
import PaymentOptionToggle from './paymentOptionToggle';
import { paymentTiers, rlpMembershipLevels } from './constants';
import PaymentForm from './paymentForm';
import usePaymentForm from './usePaymentForm';
import PaymentButton from './paymentButton';

const RLPJoin: React.FC = () => {
  const [step, setStep] = useState<JoinSteps>(JoinSteps.SustainerLevel);

  const [membershipType, setMembershipType] = useState<MembershipTypes>(
    MembershipTypes.Sustaining
  );

  const [amountSelected, setAmountSelected] = useState<number>(5000);

  const [frequencySelected, setFrequencySelected] = useState<RLPDonorOptions>(
    RLPDonorOptions.month
  );

  const {
    paymentFormData,
    onInputChange,
    loading,
    onSubmit,
  } = usePaymentForm(undefined, paymentTiers.general, () =>
    setStep(JoinSteps.MembershipQuestionnaire)
  );

  return (
    <div className="pb-10">
      <Header1 tag="h1" className="text-rlp-green uppercase text-center">
        Become a Reparations Legacy Donor
      </Header1>
      <p className="text-center mt-4 px-20">
        Take a stand in repairing the economic and social damage of enslavement
        and ongoing exploitation of African people through the redistribution of
        resources toward the Black self-determination programs of the Uhuru
        Movement.
      </p>

      {step === JoinSteps.SustainerLevel && (
        <>
          <div
            className="flex flex-wrap mt-8 mx-auto"
            style={{ maxWidth: 550 }}
          >
            <MembershipToggle
              isSelected={membershipType === MembershipTypes.Sustaining}
              onToggle={() => setMembershipType(MembershipTypes.Sustaining)}
            >
              Reparations Legacy Donor
            </MembershipToggle>
            <MembershipToggle
              isSelected={membershipType === MembershipTypes.General}
              onToggle={() => setMembershipType(MembershipTypes.General)}
            >
              Reparations Legacy Advocate
            </MembershipToggle>
            {rlpMembershipLevels.map(({ amount }) => (
              <PaymentOptionToggle
                isSelected={amount === amountSelected}
                onToggle={() => setAmountSelected(amount)}
                key={amount}
              >
                ${numberWithCommas(amount)}
              </PaymentOptionToggle>
            ))}
          </div>
          <div
            className="flex justify-end mt-1 mx-auto"
            style={{ maxWidth: 550 }}
          >
            <button
              type="button"
              className="uppercase text-rlp-green hover:text-black focus:outline-none"
            >
              Custom Amount
            </button>
          </div>
          <div
            className="flex flex-wrap mx-auto mt-6"
            style={{ maxWidth: 550 }}
          >
            <div className="font-medium uppercase min-w-full px-1 pb-2 min-w-full">
              Choose your Reparations Frequency
            </div>
            <RadioButton
              label="Monthly"
              onChange={(newSelected) =>
                setFrequencySelected(newSelected as RLPDonorOptions)
              }
              selected={frequencySelected}
              value={RLPDonorOptions.month}
            />
            <RadioButton
              label="Quarterly"
              onChange={(newSelected) =>
                setFrequencySelected(newSelected as RLPDonorOptions)
              }
              selected={frequencySelected}
              value={RLPDonorOptions.quarter}
            />
            <RadioButton
              label="Yearly"
              onChange={(newSelected) =>
                setFrequencySelected(newSelected as RLPDonorOptions)
              }
              selected={frequencySelected}
              value={RLPDonorOptions.year}
            />
            <RadioButton
              label="One Time"
              onChange={(newSelected) =>
                setFrequencySelected(newSelected as RLPDonorOptions)
              }
              selected={frequencySelected}
              value={RLPDonorOptions.oneTime}
            />
          </div>
          {/* {selectedPaymentLevel && ( */}
          {/*  <Header2 className="uppercase text-center mt-8"> */}
          {/*    Join as a{' '} */}
          {/*    <span className="underline">{selectedPaymentLevel.level}</span>{' '} */}
          {/*    Sustainer */}
          {/*  </Header2> */}
          {/* )} */}
          <PaymentButton
            onSubmit={() => setStep(JoinSteps.PaymentInfo)}
            amountSelected={amountSelected}
            membershipType={membershipType}
            paymentFrequency={frequencySelected}
          />
        </>
      )}
      {step === JoinSteps.PaymentInfo && (
        <PaymentForm
          onSubmit={onSubmit}
          onInputChange={onInputChange}
          data={paymentFormData}
          amountSelected={amountSelected}
          membershipType={membershipType}
          paymentFrequency={frequencySelected}
          loading={loading}
        />
      )}
    </div>
  );
};

export default RLPJoin;
