import React, { useEffect, useState } from 'react';

import { Link, navigate } from 'gatsby';
import { JoinSteps, MembershipTypes, PayReparationsSteps } from './types';
import { Header1, Header2 } from '../headers';
import { numberWithCommas } from '../../utils';
import MembershipToggle from './membershipToggle';
import PaymentOptionToggle from './paymentOptionToggle';
import {
  paymentTiers,
  usmGeneralLevels,
  usmSustainerLevels,
} from './constants';
import PaymentForm from './paymentForm';
import usePaymentForm from './usePaymentForm';
import PaymentButton from './paymentButton';
import Video from '../video';
import CustomAmount from './customAmount';
// eslint-disable-next-line import/extensions,import/no-unresolved
import { JoinQuery } from '../../../types/graphql-types';
import useQuestionnaireForm from './useQuestionnaireForm';
import QuestionnaireForm from './questionnaireForm';

type Props = {
  hash: string;
  data: JoinQuery;
};

const USMJoin: React.FC<Props> = ({ hash, data }: Props) => {
  const hashStripped = hash.substr(1);
  const [step, setStep] = useState<JoinSteps>(
    Object.values(JoinSteps).includes(hashStripped as JoinSteps)
      ? (hashStripped as JoinSteps)
      : JoinSteps.SustainerLevel
  );

  useEffect(() => {
    navigate(`/join#${JoinSteps.SustainerLevel}`, { replace: true });
  }, []);

  useEffect(() => {
    if (hashStripped === '' && step === JoinSteps.SustainerLevel) {
      navigate(`/join#${JoinSteps.SustainerLevel}`, { replace: true });
    }
    if (hashStripped !== step) {
      if (Object.values(JoinSteps).includes(hashStripped as JoinSteps)) {
        setStep(hashStripped as JoinSteps);
      }
    }
  }, [hashStripped, step]);

  const onSetStep = (newStep: JoinSteps) => {
    navigate(`/join#${newStep}`);
    setStep(newStep);
  };

  const [membershipType, setMembershipType] = useState<MembershipTypes>(
    MembershipTypes.Sustaining
  );

  const {
    onTieredAmountSelected,
    onCustomAmountSelected,
    tieredAmountSelected,
    customAmountSelected,
    amountDollars,
    paymentFormData,
    error: paymentError,
    loading: paymentLoading,
    onInputChange: paymentInputChange,
    onSubmit: paymentSubmit,
    rowId,
  } = usePaymentForm(
    membershipType === MembershipTypes.Sustaining ? 'monthly' : 'yearly',
    membershipType === MembershipTypes.Sustaining
      ? paymentTiers.malcolmX
      : paymentTiers.general,
    () => onSetStep(JoinSteps.MembershipQuestionnaire)
  );

  const {
    error: questionnaireError,
    loading: questionnaireLoading,
    onInputChange: questionnaireInputChange,
    onSubmit: questionnaireSubmit,
    questionnaireFormData,
  } = useQuestionnaireForm(paymentFormData, rowId || 0, () =>
    onSetStep(JoinSteps.ThankYouPage)
  );

  const onSetMembershipType = (newMembershipType: MembershipTypes): void => {
    setMembershipType(newMembershipType);

    // set default values when changing back and forth
    if (newMembershipType === MembershipTypes.Sustaining) {
      onTieredAmountSelected(paymentTiers.malcolmX);
    } else {
      onTieredAmountSelected(paymentTiers.general);
    }
  };

  const onSetCustomAmount = (amount: number | undefined): void =>
    onCustomAmountSelected(
      typeof amount !== 'undefined'
        ? {
            amount,
            type:
              membershipType === MembershipTypes.Sustaining
                ? 'monthly'
                : 'yearly',
          }
        : undefined
    );

  return (
    <div className="pb-10">
      {(step === JoinSteps.SustainerLevel ||
        step === JoinSteps.PaymentInfo) && (
        <>
          <Header1 tag="h1" className="text-usm-dark-red uppercase text-center">
            Become a member of the Uhuru Solidarity Movement
          </Header1>
          <div className="mt-3 w-full md:w-96 mx-auto">
            {data.joinVideo?.thumbnail && data.joinVideo.embedUrl && (
              <Video
                videoUrl={data.joinVideo.embedUrl}
                photo={data.joinVideo.thumbnail}
              />
            )}
          </div>
          <p className="text-center mt-4">
            Join the reparations organization of white people in solidarity with
            Black Liberation.
          </p>
          <p className="text-center mt-4">
            If you are already a sustaining member{' '}
            <Link
              to="https://billing.stripe.com/p/login/aEU3dJdE8ftl7Ti3cc"
              target="_blank"
            >
              manage your sustaining membership here.
            </Link>
          </p>
        </>
      )}
      {(step === JoinSteps.MembershipQuestionnaire ||
        step === JoinSteps.ThankYouPage) && (
        <>
          <Header1
            tag="h1"
            className="text-usm-dark-red uppercase text-center md:px-10"
          >
            Thank you for joining the Uhuru Solidarity Movement
          </Header1>
          <p className="text-center mt-4 md:px-16">
            Welcome to the reparations organization of white people in
            solidarity with Black Liberation.
            <br />
            <span className="underline">
              <a
                href="https://calendly.com/uhurusolidarity/orientation"
                target="_blank"
                rel="noreferrer"
              >
                Make sure you sign up for orientation.
              </a>
            </span>
          </p>
        </>
      )}
      {step === JoinSteps.SustainerLevel && (
        <>
          <div
            className="flex flex-wrap mt-8 mx-auto"
            style={{ maxWidth: 550 }}
          >
            {paymentError && (
              <div className="text-center min-w-full text-xs text-usm-dark-red mb-2">
                {paymentError}
              </div>
            )}
            <MembershipToggle
              isSelected={membershipType === MembershipTypes.Sustaining}
              onToggle={() => onSetMembershipType(MembershipTypes.Sustaining)}
            >
              Sustaining
            </MembershipToggle>
            <MembershipToggle
              isSelected={membershipType === MembershipTypes.General}
              onToggle={() => onSetMembershipType(MembershipTypes.General)}
            >
              General
            </MembershipToggle>
            {membershipType === MembershipTypes.Sustaining &&
              usmSustainerLevels.map((level) => (
                <PaymentOptionToggle
                  isSelected={level.name === tieredAmountSelected?.name}
                  onToggle={() => onTieredAmountSelected(level)}
                  key={level.name}
                >
                  ${numberWithCommas(level.amount)}
                </PaymentOptionToggle>
              ))}
            {membershipType === MembershipTypes.General &&
              usmGeneralLevels.map((level) => (
                <PaymentOptionToggle
                  isSelected={level.name === tieredAmountSelected?.name}
                  onToggle={() => onTieredAmountSelected(level)}
                  key={level.name}
                  fullSize
                >
                  {level.name}: ${numberWithCommas(level.amount)}
                </PaymentOptionToggle>
              ))}
          </div>
          <CustomAmount
            isExpanded={typeof customAmountSelected !== 'undefined'}
            defaultCustomAmount={3000}
            onCustomAmountChange={onSetCustomAmount}
          />
          {membershipType === MembershipTypes.Sustaining &&
            tieredAmountSelected && (
              <Header2 className="uppercase text-center mt-8">
                Join as a{' '}
                <span className="underline">{tieredAmountSelected.name}</span>{' '}
                Sustainer
              </Header2>
            )}
          <PaymentButton
            onSubmit={() => {
              if (!paymentError) {
                onSetStep(JoinSteps.PaymentInfo);
              }
            }}
            amountSelected={amountDollars}
            membershipType={membershipType}
          />
        </>
      )}
      {step === JoinSteps.PaymentInfo && (
        <PaymentForm
          onSubmit={paymentSubmit}
          onInputChange={paymentInputChange}
          data={paymentFormData}
          error={paymentError}
          amountSelected={amountDollars}
          membershipType={membershipType}
          loading={paymentLoading}
        />
      )}
      {step === JoinSteps.MembershipQuestionnaire && (
        <QuestionnaireForm
          data={questionnaireFormData}
          onSubmit={questionnaireSubmit}
          loading={questionnaireLoading}
          error={questionnaireError}
          onInputChange={questionnaireInputChange}
          questionnaireType="membership"
          includeComments
        />
      )}
      {step === JoinSteps.ThankYouPage && (
        <>
          <div className="mt-3 w-full md:w-96 mx-auto">
            {data.joinVideo?.thumbnail && data.joinVideo.embedUrl && (
              <Video
                videoUrl={data.joinVideo.embedUrl}
                photo={data.joinVideo.thumbnail}
              />
            )}
          </div>
          <p className="text-center mt-4 md:px-8">
            Your contribution of reparations to the African self-determination
            programs of the Uhuru Movement is making a profound difference. It
            is a stand of solidarity, not charity. We salute you for your
            contribution to the anti-colonial movement of African and oppressed
            peoples fighting to build a future free from oppression and
            exploitation. This is what reparations looks like.
          </p>
        </>
      )}
      {(step === JoinSteps.SustainerLevel ||
        step === JoinSteps.PaymentInfo) && (
        <p className="mt-8 md:px-10 text-center">
          <span className="font-medium">Where does the money go?</span> The
          funds from your membership go towards the many economic programs of
          the Uhuru Movement. Currently, the primary program is The Black Power
          Blueprint. Joining USM is the most progressive stance you can take, it
          means true material solidarity with the struggle of the African
          working class.
        </p>
      )}
    </div>
  );
};

export default USMJoin;
