import React from 'react';
import { graphql } from 'gatsby';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { HomeLayout } from '../components/homeLayout';
import SEO from '../components/seo';

// eslint-disable-next-line import/extensions,import/no-unresolved
import { JoinQuery } from '../../types/graphql-types';
import USMJoin from '../components/join/usmJoin';
import RLPJoin from '../components/join/rlpJoin';
import BannerPage from '../components/join/bannerPage';

const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUBLIC_KEY || '');

type Props = {
  location: {
    hash: string;
  };
  data: JoinQuery;
};

const Join: React.FC<Props> = ({ data, location }: Props) => (
  <HomeLayout pathname="/join">
    <SEO
      title="White Reparations to African People"
      description="Uhuru Solidarity Movement is the reparations organization of white people under the leadership of the African People's Socialist Party"
    />

    <BannerPage joinBanner={data.joinBanner}>
      <Elements stripe={stripePromise}>
        {process.env.GATSBY_DOMAIN_ID !== 'rlp' && (
          <USMJoin hash={location.hash} data={data} />
        )}
        {process.env.GATSBY_DOMAIN_ID === 'rlp' && <RLPJoin />}
      </Elements>
    </BannerPage>
  </HomeLayout>
);

export const query = graphql`
  query Join {
    joinBanner: file(relativePath: { eq: "join-banner-2.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, width: 1720)
      }
    }
    joinVideo: contentfulVideo(
      title: { eq: "Joining the Fight for Reparations" }
    ) {
      embedUrl
      thumbnail {
        gatsbyImageData(layout: CONSTRAINED, width: 480, cropFocus: CENTER)
      }
    }
  }
`;

export default Join;
