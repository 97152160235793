import React from 'react';

import { ToggleProps } from './types';
import { getToggleButtonClasses } from './utils';

const MembershipToggle: React.FC<ToggleProps> = ({
  isSelected,
  onToggle,
  children,
}: ToggleProps) => (
  <div className="flex-1 w-1/2 p-1" style={{ minWidth: '50%' }}>
    <button
      type="button"
      className={getToggleButtonClasses(isSelected)}
      onClick={isSelected ? undefined : onToggle}
    >
      {children}
    </button>
  </div>
);

export default MembershipToggle;
